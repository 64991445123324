<script>
export default {
  
}
</script>

<template>
  <h1>Em construção...</h1>  
</template>

<style lang="scss" scoped>

</style>
